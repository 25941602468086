/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TeamPageData } from "src/interfaces/TeamPageData";
import hubspotImg from "../../assets/images/HubspotLogoTransparent.png";
import CircularActionButton from "../buttons/CircularActionButton";
import {
  faArrowsRotate,
  faCircleExclamation,
  faMagnifyingGlass,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import IdDisplay from "./IdDisplay";
import { adminApi, hubspotApi } from "src/api";
import DisplayEditText from "./DisplayEditText";
import useModal from "src/hooks/useModal";
import EditHubspotIDModal from "../modals/EditHubspotIDModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSpinner from "../loading/LoadingSpinner";
import { parsePhoneNumber } from "src/helpers/parseDatas";
import { timeAgo } from "src/helpers/parseEpochs";
import useAlert from "src/hooks/useAlert";

interface HubspotCardProps {
  team: TeamPageData;
  fetchTeam: () => void;
  dealioSitePlanIDGlobal: number | null;
}

export interface HubspotData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  dealioPlanID: number | null;
  dealioAccountID: number | null;
  sendFuseTeamID: number | null;
  sendFuseSubscriptionID: number | null;
}

const HubspotCard = ({
  team,
  fetchTeam,
  dealioSitePlanIDGlobal,
}: HubspotCardProps) => {
  const [isLoadingHubspotData, setIsLoadingHubspotData] =
    useState<boolean>(false);
  const { openModalWith } = useModal();
  const [hubspotError, setHubspotError] = useState<boolean>(false);
  const [hubspotData, setHubspotData] = useState<HubspotData | null>(null);
  const [editedHubspotData, setEditedHubspotData] =
    useState<HubspotData | null>(null);
  const [isSyncingHubspotData, setIsSyncingHubspotData] =
    useState<boolean>(false);
  const [isLoadingSearchHubspot, setIsLoadingSearchHubspot] =
    useState<boolean>(false);

  const { hubspotID } = team.team;

  const { setAlert } = useAlert();

  useEffect(() => {
    setEditedHubspotData(hubspotData);
  }, [hubspotData]);

  const handleInputChange = (field: keyof HubspotData, value: string) => {
    if (editedHubspotData) {
      setEditedHubspotData({ ...editedHubspotData, [field]: value });
    }
  };

  const hubspotProperties = [
    "firstname",
    "lastname",
    "email",
    "phone",
    "sf__subscriptionid",
    "sf__teamid",
    "dealio__accountid",
    "dealio__siteplanid",
  ];

  const fetchHubspotData = async () => {
    setHubspotError(false);
    if (hubspotID) {
      setIsLoadingHubspotData(true);
      try {
        const res = await hubspotApi.getContact(
          Number(hubspotID),
          hubspotProperties
        );
        // console.log({ res });
        setHubspotData({
          firstName: res.properties.firstname,
          lastName: res.properties.lastname,
          email: res.properties.email,
          phoneNumber: res.properties.phone,
          sendFuseTeamID: res.properties.sf__teamid,
          sendFuseSubscriptionID: res.properties.sf__subscriptionid,
          dealioAccountID: res.properties.dealio__accountid,
          dealioPlanID: res.properties.dealio__siteplanid,
        });
        setIsLoadingHubspotData(false);
      } catch (err) {
        console.error(err);
        setHubspotError(true);
        setIsLoadingHubspotData(false);
      }
    } else {
      setHubspotError(true);
    }
  };

  const searchHubspotForContact = async () => {
    setIsLoadingSearchHubspot(true);
    try {
      const res = await hubspotApi.searchHubspotForContact({
        filterGroups: [
          {
            filters: [
              {
                propertyName: "email",
                operator: "EQ",
                value: team.user.email,
              },
            ],
          },
        ],
        properties: ["email"],
      });
      if (res.length > 0) {
        await adminApi.updateTeam({
          ...team.team,
          hubspotID: res[0].properties.hs_object_id,
        });
        setHubspotError(false);
        syncHubspotData();
        fetchTeam();
      } else {
        setAlert({
          display: true,
          message: `No Hubspot contact found for email ${team.user.email}`,
          type: "warning",
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingSearchHubspot(false);
    }
  };

  const syncHubspotData = async () => {
    setIsSyncingHubspotData(true);
    try {
      await hubspotApi.syncContact(team.team.id);
      fetchHubspotData();
    } catch (err) {
      console.error(err);
    } finally {
      setIsSyncingHubspotData(false);
      fetchTeam();
    }
  };

  useEffect(() => {
    fetchHubspotData();
  }, [hubspotID]);

  const handleClickHubspotID = () => {
    openModalWith({
      title: "Edit HubspotID",
      body: <EditHubspotIDModal team={team} fetchTeam={fetchTeam} />,
      hideButtons: true,
    });
  };

  return (
    <>
      <div className="card w-full rounded-lg bg-white px-6 py-4 shadow-lg dark:bg-card-dark">
        <div className="flex w-full items-center justify-between">
          <div className="flex w-24 items-center">
            <img src={hubspotImg} alt={"Hubspot Logo"} className="w-16" />
          </div>
          <IdDisplay
            ID={hubspotID}
            label="HubspotID"
            onClick={handleClickHubspotID}
            error={hubspotError}
          />
          <div className="flex w-24 items-center justify-end gap-2">
            {!hubspotID && (
              <CircularActionButton
                icon={faMagnifyingGlass}
                onClick={searchHubspotForContact}
                tooltip="Search For Hubspot Contact"
                delayTooltipShow={200}
                isLoading={isLoadingSearchHubspot}
                loadingClassName="text-[#F2785B]"
              />
            )}
            <CircularActionButton
              icon={faRotateRight}
              onClick={fetchHubspotData}
              tooltip="Refresh Data"
              delayTooltipShow={200}
              isLoading={isLoadingHubspotData}
              loadingClassName="text-[#F2785B]"
            />
            <CircularActionButton
              icon={faArrowsRotate}
              onClick={syncHubspotData}
              tooltip="Sync Data"
              delayTooltipShow={200}
              isLoading={isSyncingHubspotData}
              loadingClassName="text-[#F2785B]"
            />
          </div>
        </div>
        <div className="mb-4 mt-0.5 flex w-full items-center justify-center">
          {isLoadingHubspotData ? (
            <LoadingSpinner className="mt-2 h-8 w-8 text-[#F2785B]" />
          ) : hubspotError ? (
            <div className="mt-4 flex items-center justify-center gap-2">
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className="h-4 w-4 text-error"
              />
              <p className="text-error">
                HubspotID not found, please search or update
              </p>
            </div>
          ) : (
            <div className="flex w-full  gap-2">
              <div className="w-1/2">
                <DisplayEditText
                  label={"First Name"}
                  name={"firstName"}
                  value={hubspotData?.firstName || "null"}
                  disabled={true}
                />
                <DisplayEditText
                  label={"Email"}
                  name="email"
                  value={hubspotData?.email || "null"}
                  disabled={true}
                />
                <DisplayEditText
                  label={"SendFuseTeamID"}
                  name={"sendFuseTeamID"}
                  value={editedHubspotData?.sendFuseTeamID || ""}
                  disabled={true}
                  error={Number(hubspotData?.sendFuseTeamID) !== team.team.id}
                  success={Number(hubspotData?.sendFuseTeamID) === team.team.id}
                  onChange={(e) =>
                    handleInputChange("sendFuseTeamID", e.target.value)
                  }
                />
                <DisplayEditText
                  label={"SendFuse SubscriptionID"}
                  name={"sendFuseSubscriptionID"}
                  value={editedHubspotData?.sendFuseSubscriptionID || ""}
                  disabled={true}
                  error={
                    Number(hubspotData?.sendFuseSubscriptionID) !==
                    team.team.subscriptionID
                  }
                  success={
                    Number(hubspotData?.sendFuseSubscriptionID) ===
                    team.team.subscriptionID
                  }
                  onChange={(e) =>
                    handleInputChange("sendFuseSubscriptionID", e.target.value)
                  }
                />
              </div>
              <div className="w-1/2">
                <DisplayEditText
                  label={"Last Name"}
                  name="lastName"
                  value={hubspotData?.lastName || "null"}
                  disabled={true}
                />
                <DisplayEditText
                  label={"Phone Number"}
                  name="phoneNumber"
                  value={parsePhoneNumber(hubspotData?.phoneNumber || "")}
                  disabled={true}
                />
                <DisplayEditText
                  label={"Dealio AccountID"}
                  name="dealioAccountID"
                  value={editedHubspotData?.dealioAccountID || ""}
                  disabled={true}
                  error={
                    Number(hubspotData?.dealioAccountID) !==
                    team.team.realeflowID
                  }
                  success={
                    Number(hubspotData?.dealioAccountID) ===
                    team.team.realeflowID
                  }
                  onChange={(e) =>
                    handleInputChange("dealioAccountID", e.target.value)
                  }
                />
                <DisplayEditText
                  label={"Dealio Site Plan ID"}
                  name="dealioPlanID"
                  value={editedHubspotData?.dealioPlanID || ""}
                  disabled={true}
                  onChange={(e) =>
                    handleInputChange("dealioPlanID", e.target.value)
                  }
                  success={
                    Number(hubspotData?.dealioPlanID) === dealioSitePlanIDGlobal
                  }
                  error={
                    Number(hubspotData?.dealioPlanID) !== dealioSitePlanIDGlobal
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div>
          <p className="text-sm text-zinc-500 dark:text-zinc-400">
            * Time since last sync to Hubspot:{" "}
            <span className="font-bold text-[#F2785B] ">
              {team.team.lastHubspotSync
                ? timeAgo(team.team.lastHubspotSync.toString(), true)
                : "Never"}
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default HubspotCard;
