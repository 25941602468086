/* eslint-disable @typescript-eslint/no-unused-vars */

import { Offer } from "src/interfaces/offer";
import useTheme from "src/hooks/useTheme";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

ChartJS.register(ArcElement, Tooltip, Legend);

interface OfferStatusProps {
  offers: Offer[];
  isLoading: boolean;
}

const OfferStatusChart = ({ offers, isLoading }: OfferStatusProps) => {
  const { tailwindTheme } = useTheme();

  let labels = ["Created", "Sent", "Negotiating", "Accepted", "Denied"];

  function countStatuses(): number[] {
    const statusOrder: any = {
      created: 1,
      sent: 2,
      negotiating: 3,
      accepted: 4,
      denied: 5,
    };
    let counts: Record<string, number> = {
      created: 0,
      sent: 0,
      negotiating: 0,
      accepted: 0,
      denied: 0,
    };

    for (const offer of offers) {
      if (offer.status in counts) {
        counts[offer.status]++;
      }
    }

    let result = Object.keys(counts).map((status) => ({
      status,
      count: counts[status],
    }));
    result.sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
    return result.map((item) => item.count);
  }

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Status",
        color: "#FFFFFF",
        data: countStatuses(),
        backgroundColor: [
          tailwindTheme.theme1,
          tailwindTheme.theme4,
          tailwindTheme.theme5,
          tailwindTheme.theme2,
          tailwindTheme.theme3,
        ],
        borderWidth: 0,
      },
    ],
  };

  const options: ChartOptions = {
    plugins: {
      legend: {
        labels: {
          color: "#000000",
          boxWidth: 16,
          boxHeight: 16,
          font: {
            size: 14,
            family: "sans-serif",
          },
        },
      },
      title: {
        display: true,
        text: "Status",
        color: "#71717a",
        padding: 0,
        font: {
          size: 40,
          family: "sans-serif",
        },
      },
    },
  };

  return (
    <>
      <div className="card h-full bg-card-light shadow-lg">
        <div className="flex flex-grow columns-1 flex-col items-center py-2 ">
          <div className="mb-3">
            {isLoading ? (
              <div className="flex w-full flex-col items-center justify-center">
                <div className="mb-8 mt-4">
                  <Skeleton count={1} height={60} width={300} />
                </div>
                <Skeleton circle={true} height={200} width={200} />
              </div>
            ) : (
              <>
                 {/* <Doughnut data={chartData} options={options} /> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferStatusChart;
