import React, { useEffect, useState } from "react";
import { TeamPageData } from "src/interfaces/TeamPageData";
import dashImg from "../../assets/images/SendFuseDashLogo.png";
import CircularActionButton from "../buttons/CircularActionButton";
import {
  faArrowsRotate,
  faCar,
  faCircleCheck,
  faCircleXmark,
  faHouse,
  faHouseCircleCheck,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleButton from "../buttons/ToggleButton";
import useModal from "src/hooks/useModal";
import DashSubscriptionModal from "../modals/DashSubscriptionModal";
import { adminApi, statisticsApi } from "src/api";
import useAlert from "src/hooks/useAlert";
// import dealioSitePlans from "src/data/DealioSitePlans";
import DashDealioModal from "../modals/DashDealioModal";

interface DashRequirementProps {
  label: string;
  isCompleted: boolean;
  onClick: () => void;
}

const DashRequirement = ({
  label,
  isCompleted,
  onClick,
}: DashRequirementProps) => {
  return (
    <div onClick={onClick}>
      <div className="flex items-center gap-1">
        <FontAwesomeIcon
          icon={isCompleted ? faCircleCheck : faCircleXmark}
          className={isCompleted ? "text-success" : "text-warning"}
        />
        <span
          className={
            isCompleted
              ? "text-success"
              : "cursor-pointer text-warning hover:underline"
          }
        >
          {label}
        </span>
      </div>
    </div>
  );
};

interface DashCardProps {
  team: TeamPageData;
  fetchTeam: () => void;
  dealioSitePlanIDGlobal: number | null;
  dashUpgradeTagInKeapGlobal: boolean;
}

export interface TeamDashStats {
  drives: number;
  properties: number;
  propertiesAdded: number;
}

const DashCard = ({
  team,
  fetchTeam,
  dealioSitePlanIDGlobal,
  dashUpgradeTagInKeapGlobal,
}: DashCardProps) => {
  const { openModalWith } = useModal();

  const { setAlert } = useAlert();

  const [isLoadingDashActive, setIsLoadingDashActive] =
    useState<boolean>(false);

  const refreshCard = () => {};

  const [isPaidDealio, setIsPaidDealio] = useState<boolean>(true);

  const [isReadyInvite, setIsReadyInvite] = useState<boolean>(
    team.team.isDash && isPaidDealio && team.subscription.canDash
  );

  const handleDashActive = async () => {
    setIsLoadingDashActive(true);
    try {
      await adminApi.updateTeam({
        id: team.team.id,
        isDash: !team.team.isDash,
      });
      fetchTeam();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingDashActive(false);
    }
  };

  useEffect(() => {
    if (team.team.isDash && isPaidDealio && team.subscription.canDash) {
      setIsReadyInvite(true);
    } else {
      setIsReadyInvite(false);
    }
  }, [team, isPaidDealio]);

  useEffect(() => {
    // const isPlanWithDash = dealioSitePlans.some(
    //   (plan) => plan.id === dealioSitePlanIDGlobal && plan.hasDash
    // );
    // setIsPaidDealio(isPlanWithDash);
    setIsPaidDealio(true);
  }, [dealioSitePlanIDGlobal]);

  const handleSubscriptionClick = () => {
    openModalWith({
      title: dashUpgradeTagInKeapGlobal
        ? "Convert Team To New Platinum"
        : "Platinum Subscription Required",
      body: (
        <DashSubscriptionModal
          team={team}
          fetchTeam={fetchTeam}
          isDashTagInKeap={dashUpgradeTagInKeapGlobal}
        />
      ),
      hideButtons: true,
    });
  };

  const handleDealioClick = () => {
    openModalWith({
      title: "Paid Dealio Account Required",
      body: (
        <DashDealioModal
          sitePlanID={dealioSitePlanIDGlobal}
          teamID={team.team.id}
        />
      ),
      hideButtons: true,
    });
  };

  useEffect(() => {
    if (dealioSitePlanIDGlobal === 772 || dealioSitePlanIDGlobal === 430) {
      setIsPaidDealio(true);
    }
  }, [dealioSitePlanIDGlobal]);

  const handleInviteToDash = async () => {
    try {
      const res = await adminApi.inviteToDash(team.team.id);
      setAlert({
        display: true,
        type: "success",
        message: res,
      });
    } catch (err: any) {
      setAlert({
        display: true,
        type: "error",
        message:
          err?.message || "There was an error inviting this team to Dash",
      });
    } finally {
      fetchTeam();
    }
  };

  const initialDashStats: TeamDashStats = {
    drives: 0,
    properties: 0,
    propertiesAdded: 0,
  };
  const [dashStats, setDashStats] = useState<TeamDashStats>(initialDashStats);

  useEffect(() => {
    const fetchDashStats = async () => {
      if (team.team.wasEverDash) {
        try {
          const stats = await statisticsApi.getTeamDashStats(team.team.id);
          setDashStats(stats);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchDashStats();
  }, [team.team.wasEverDash, team.team.id]);

  const handleInterestedChange = async () => {
    try {
      await adminApi.updateTeam({
        id: team.team.id,
        isInterestedDash: !team.team.isInterestedDash,
      });
      fetchTeam();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="card w-full rounded-lg bg-white px-6 py-4 shadow-lg dark:bg-card-dark">
        <div className="flex w-full items-center justify-between">
          <div className="flex w-24 items-center">
            <img src={dashImg} alt={"Keap Logo"} className="w-24" />
          </div>
          {!team.team.isDash && (
            <label
              htmlFor={`interested-${team.team.id}`}
              className="flex cursor-pointer flex-row items-center justify-between gap-2 rounded-lg bg-back-light px-2 py-1 dark:bg-back-dark"
              onChange={handleInterestedChange}
            >
              <input
                id={`interested-${team.team.id}`}
                type="checkbox"
                checked={team.team.isInterestedDash}
                onChange={handleInterestedChange}
                className="checkbox-secondary checkbox checkbox-sm"
              />
              <div className="font-semibold text-zinc-500 dark:text-zinc-400">Interested</div>
            </label>
          )}
          <div className="flex w-24 items-center justify-end gap-2">
            <CircularActionButton icon={faArrowsRotate} onClick={refreshCard} />
          </div>
        </div>
        <div className="mt-4 flex w-full items-center justify-between">
          <DashRequirement
            onClick={
              team.subscription.canDash ? () => {} : handleSubscriptionClick
            }
            isCompleted={team.subscription.canDash}
            label={"SendFuse Platinum"}
          />
          <DashRequirement
            onClick={isPaidDealio ? () => {} : handleDealioClick}
            isCompleted={isPaidDealio}
            label={"Dealio Pro"}
          />
          <ToggleButton
            isChecked={team.team.isDash}
            name="isDash"
            label="Active"
            isLoading={isLoadingDashActive}
            onClick={handleDashActive}
            disabled={
              team.team.isDash
                ? false
                : isPaidDealio && team.subscription.canDash
                ? false
                : true
            }
          />

          <div className="relative">
            <button
              disabled={!isReadyInvite}
              className={
                isReadyInvite
                  ? "btn-secondary btn-sm btn"
                  : "btn-sm btn disabled:text-zinc-500"
              }
              onClick={handleInviteToDash}
            >
              <FontAwesomeIcon icon={faPaperPlane} />
              Invite
            </button>
            {team.team.wasEverDash && (
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="absolute -right-[8px] -top-[8px] text-success"
              />
            )}
          </div>
        </div>
        {team.team.wasEverDash && (
          <div className="mt-4 w-full ">
            <div className="stats  w-full bg-back-light shadow dark:bg-back-dark">
              <div className="stat">
                <div className="stat-title text-zinc-600 dark:text-gray-400">
                  <FontAwesomeIcon icon={faCar} className="mr-1.5" />
                  Drives
                </div>
                <div className="stat-value text-zinc-500 dark:text-slate-300">
                  {dashStats?.drives || 0}
                </div>
              </div>

              <div className="stat">
                <div className="stat-title text-zinc-600 dark:text-gray-400">
                  <FontAwesomeIcon icon={faHouse} className="mr-1.5" />
                  Properties Found
                </div>
                <div className="stat-value text-zinc-500 dark:text-slate-300">
                  {dashStats?.properties || 0}
                </div>
              </div>
              <div className="stat">
                <div className="stat-title text-zinc-600 dark:text-gray-400">
                  <FontAwesomeIcon
                    icon={faHouseCircleCheck}
                    className="mr-1.5"
                  />
                  Properties Added
                </div>
                <div className="stat-value text-zinc-500 dark:text-slate-300">
                  {dashStats?.propertiesAdded || 0}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DashCard;
